"use strict"

const getVueAppEnv = (key) =>
  process.env[`VUE_APP_${String(process.env.VUE_APP_STAGE).trim()}${key}`.toUpperCase()]

const poolDataBusiness = {
  UserPoolId: getVueAppEnv("CognitoUserPoolIdForBusiness"),
  ClientId: getVueAppEnv("CognitoUserPoolAppIdForBusiness"),
}

const poolDataClients = {
  UserPoolId: getVueAppEnv("CognitoUserPoolIdForClients"),
  ClientId: getVueAppEnv("CognitoUserPoolAppIdForClients"),
}

module.exports = {
  poolDataBusiness,
  poolDataClients
}