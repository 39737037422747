import { ApiAxios } from "./../../../../js/apiAxios"
import { urlAppStage, httpsApiDomain } from "./../../../../js/urls"

class ApiAdmin extends ApiAxios {

  any() {
    const apiPostAny = async (eventName, params) => this.apiPost("a", eventName, params, { skipAuth: true, vueAppUpdatingWithoutModal: true })
    return {
      /** test open api */
      apiVersion: () => apiPostAny("apiVersion"),
      /** get business details */
      getBusinessProfile: bId => apiPostAny("getBusinessProfile", { bId }),
      /** Get Pool Data
       * @param {String} email */
      getPoolData: (email) => apiPostAny("getPoolData", { email }),
    }
  }
}

if (urlAppStage) //only print if not on Production
  console.log("api httpsApiDomain", httpsApiDomain)

const api = new ApiAdmin(httpsApiDomain)

export default api