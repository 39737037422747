var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LoginApp", class: { logged: _vm.logged } },
    [
      _c(
        "v-app",
        {
          style: {
            background: _vm.$vuetify.theme.themes[_vm.theme].background,
          },
        },
        [
          _c(
            "v-main",
            { staticClass: "loginAppMain" },
            [
              _c("CredentialForm", {
                staticClass: "credentialForm",
                attrs: {
                  appName: _vm.$root.appName,
                  cognitoUser: _vm.$root.cognitoUser,
                  loadingOthers: _vm.logged,
                  api: _vm.api,
                  skipLocalLogin: "",
                },
                on: {
                  snackbar: _vm.$root.snackbar,
                  modal: _vm.$root.modal,
                  logged: _vm.onLogged,
                  cognito: _vm.onCognito,
                },
                scopedSlots: _vm._u([
                  {
                    key: "logoImage",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "logoWrapper" }, [
                          _vm.bLogoImage
                            ? _c("img", {
                                staticClass: "logo bLogoImage",
                                attrs: {
                                  alt: "Company Logo",
                                  src: _vm.bLogoImage,
                                },
                              })
                            : _vm._e(),
                          _c("img", {
                            staticClass: "logo",
                            attrs: {
                              alt: "Company Logo",
                              src: require("@/../../../img/goConnectClient-600px.png"),
                            },
                          }),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Dialog", { ref: "dialog" }),
          _c("div", { staticClass: "appStatusInner" }, [
            _c("div", {
              staticClass: "appVersion",
              domProps: { textContent: _vm._s(_vm.$root.appVersion) },
            }),
            _c(
              "div",
              {
                staticClass: "apiVersionDiv",
                class: _vm.apiA ? "apiTest" : "apiTestWaiting",
              },
              [
                _c(
                  "v-icon",
                  {
                    attrs: { small: "" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.apiVersion.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.apiA
                          ? "mdi-checkbox-marked-circle"
                          : "mdi-checkbox-blank-circle-outline"
                      )
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }