import Vue from 'vue'
import LoginApp from './LoginApp.vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import { appTypeBusiness } from "./../../../../models/types"
import CardPanel from "@shared-components/CardPanel.vue"

Vue.component("v-card-panel", CardPanel)

Vue.config.productionTip = false

const app = new Vue({
  vuetify,
  data() {
    return {
      appVersion: process.env.VUE_APP_APPBUILDNUMBER,
      appName: appTypeBusiness,
      cognitoUser: null,
    }
  },
  computed: {
    dialog() {
      return this.$el.__vue__.$refs.dialog
    },
  },
  methods: {
    snackbar(params) {
      this.dialog.snackbarShow(params)
    },
    async modal(params, callback) {
      const confirmed = await this.dialog.modalShow(params)
      if (callback)
        return callback(confirmed)
      return confirmed
    },
  },
  render: h => h(LoginApp)
}).$mount('#loginApp')

if (process.env.NODE_ENV !== "production")
  window.app = app