<template>
  <div class="Credential"></div>
</template>

<script>
import { CognitoUser, CognitoUserPool, AuthenticationDetails, CognitoUserAttribute } from "amazon-cognito-identity-js"
import { poolDataClients, poolDataBusiness } from "./../js/poolData"
import { appTypeBusiness, appTypeClient } from "../../server/models/types"
import rules from "../../server/models/rules"

export default {
  name: "Credential",
  props: {
    appName: {
      type: String,
      default: "",
    },
    cognitoUser: {
      type: Object,
      default: null,
    },
    skipLocalLogin: {
      type: Boolean,
      default: false,
    },
    api: {
      type: Object,
      default: () => null,
    },
    asyncLoginEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      poolDataInUse: {},
      cognitoUserClient: null,
      cognitoUserBusiness: null,
    }
  },
  mounted() {
    if (!this.cognitoUser && !this.skipLocalLogin) this.localLogin()
  },
  methods: {
    poolData(appName) {
      if (!appName) appName = this.appName
      if (!appName) throw new Error("No App defined. Code: 432d2bfb.")
      return appName === appTypeClient ? poolDataClients : poolDataBusiness
    },
    async getPoolData(email) {
      if (!email) throw new Error("No email defined. Code: 061725aa.")
      if (this.poolDataInUse[email]) return this.poolDataInUse[email]
      const result = await this.api.any().getPoolData(email)
      if (!result.ok) throw new Error(result.message)

      this.poolDataInUse[email] = result.data
      return this.poolDataInUse[email]
    },
    async userTokenValidation(cognitoUser) {
      const loginUrl = `//${window.location.host}/index.html`

      function goToLogin() {
        window.location.href = loginUrl
      }

      const result = await new Promise((resolve) => {
        if (cognitoUser) return resolve(cognitoUser)

        const { currentCognitoUser, appName } = this.tryGetCurrentCognitoUser()
        if (!currentCognitoUser) return resolve()

        currentCognitoUser.getSession((err, session) => {
          if (err || !session?.isValid()) return resolve()

          resolve(currentCognitoUser)
          this.userLogged({ cognitoUser: currentCognitoUser, appName })
        })
      })

      if (result) return result

      if (window.location.host.includes("localhost")) return
      if (this.asyncLoginEnabled && window.location.search.includes("async-login")) return

      goToLogin()
    },
    userLogged({ cognitoUser, appName }) {
      cognitoUser.userTokenValidation = this.userTokenValidation
      window.localStorage["appConnect"] = appName
      this.$emit("logged", { cognitoUser, appName })
    },
    async localLogin() {
      await this.userTokenValidation()
    },
    resendCode({ message, timeout }) {
      if (!message) message = "A verification code was resent to you email"
      if (!timeout) timeout = 8000 //8secs
      if (!this.cognitoUser)
        return this.$emit("snackbar", {
          id: "resendCodeFailed",
          content: "No User set",
        })

      const resendConfirmationCode = (err) => {
        if (err) {
          const message = err.message || JSON.stringify(err)
          if (message !== "User is already confirmed.")
            return this.$emit("modal", {
              id: "resendCodeErr",
              title: "Oops",
              content: err.message || JSON.stringify(err),
              btnCancelShow: false,
            })
        }
        this.$emit("snackbar", {
          id: "resendCode",
          content: message,
          timeout,
        })
      }
      return this.cognitoUser.resendConfirmationCode(resendConfirmationCode)
    },
    async confirmCode({ confirmationCode, password }) {
      if (this.cognitoUser.userNeedsConfirmPsw) {
        this.cognitoUser.confirmPassword(confirmationCode, password, this.cognitoUser.userNeedsConfirmPsw)
      } else {
        this.cognitoUser.confirmRegistration(confirmationCode, true, (err) => {
          if (err)
            return this.$emit("modal", {
              id: "confirmCodeErr",
              title: "Oops",
              content: err.message || JSON.stringify(err),
              btnCancelShow: false,
            })
          this.doSignIn({
            email: this.cognitoUser.username,
            password,
            appName: this.appName,
          })
        })
      }
    },
    async signUpSendConfirmationCode({ email, password }) {
      email = email.toLowerCase().trim()
      password = password.trim()

      const userPool = new CognitoUserPool(this.poolData(appTypeBusiness))
      const dataEmail = {
        Name: "email",
        Value: email,
      }

      const attributeEmail = new CognitoUserAttribute(dataEmail)
      const attributeList = [attributeEmail]

      userPool.signUp(email, password, attributeList, null, (err, result) => {
        if (err) {
          if (err.code === "UsernameExistsException") {
            this.$emit("snackbar", {
              id: "sendConfirmationCodeSignUpAlreadyExist",
              content: `${err.message}<br/>Trying to login...`,
            })
            return this.doSignIn({
              email,
              password,
              appName: this.appName,
            })
          }

          if (err.message.includes("PreSignUp failed with error Apologies, but sign-up is restricted"))
            err.message = err.message.replace("PreSignUp failed with error Apologies, but sign-up is restricted", "Apologies, but sign-up is restricted")

          this.$emit("modal", {
            id: "sendConfirmationCodeSignUpErr",
            title: "Oops",
            content: err.message || JSON.stringify(err),
            btnCancelShow: false,
          })
          return
        }
        const cognito = result.user
        cognito["userNeedsConfirm"] = true
        cognito["confirmationCodeSent"] = true
        this.emitCognitoUser(cognito)
        this.$emit("snackbar", {
          id: "sendConfirmationCodeSignUp",
          content: "Check your email for a verification code",
        })
      })
    },
    async forgotPassword(email) {
      const userPool = new CognitoUserPool(await this.getPoolData(email))
      const userData = {
        Username: email.toLowerCase().trim(),
        Pool: userPool,
      }
      const cognitoUser = new CognitoUser(userData)

      const self = this
      cognitoUser.forgotPassword({
        onSuccess() {
          self.$emit("passwordUpdated")
          self.$emit("snackbar", {
            id: "forgotPasswordSuccess",
            content: "Password updated. Please try to Login.",
          })
        },
        onFailure(err) {
          if (err.code === "UserNotFoundException")
            return self.$emit("snackbar", {
              id: "forgotPasswordFailureNotFound",
              content: err.message,
            })
          self.$emit("modal", {
            id: "forgotPasswordFailure",
            title: "Oops",
            content: err.message || JSON.stringify(err),
            btnCancelShow: false,
          })
        },
        inputVerificationCode() {
          self.$emit("snackbar", {
            id: "forgotPasswordInputVerificationCode",
            content: "A verification code was sent to your email.",
          })
          cognitoUser["userNeedsConfirm"] = true
          cognitoUser["userNeedsConfirmPsw"] = this
          self.$emit("cognito", cognitoUser)
          self.$emit("forgotPasswordVerificationCode")
        },
      })
    },
    async doSignIn({ email, password, appName }) {
      this.$emit("loading", true)
      if (!appName) this.appName = appName
      email = email.toLowerCase().trim()
      const authenticationData = {
        Username: email,
        Password: password,
      }
      const authenticationDetails = new AuthenticationDetails(authenticationData)
      const userPool = new CognitoUserPool(await this.getPoolData(email))
      const userData = {
        Username: email,
        Pool: userPool,
      }
      const cognitoUser = new CognitoUser(userData)

      const onSuccess = () => {
        this.$emit("loading", false)
        this.userLogged({ cognitoUser, appName })
      }

      const onFailure = (err) => {
        this.$emit("loading", false)
        if (err.message.includes("Missing required parameter ANSWER")) return

        if (err.code === "UserNotConfirmedException") {
          cognitoUser["userNeedsConfirm"] = true
          cognitoUser["confirmationCodeSent"] = true
          this.$emit("cognito", cognitoUser)
          this.$nextTick(() => {
            this.$emit("userNeedsConfirm")
            this.resendCode({
              message: `The verification code was resent to you. Please use this code on "Confirmation Code" field to complete your account creation process.`,
              timeout: 20000,
            })
          })
          return //no issue should be raised. The user can continue from confirmation code step
        }

        this.$emit("snackbar", {
          id: "signInFailure",
          content: err.message || JSON.stringify(err),
        })
      }

      const newPasswordRequired = () => {
        this.$emit("loading", false)
        this.$emit("cognito", cognitoUser)
        this.$emit("newPasswordRequired")
        this.$emit("snackbar", {
          id: "newPasswordRequired",
          content: "You need to change password",
        })
      }
      const customChallenge = async (challengeParameters) => {
        this.$emit("loading", false)
        this.$emit("snackbar", {
          id: "otpSent",
          content: "Code sent by Email",
        })

        const otpDlgInput = {
          inputComponent: "v-text-field",
          inputName: "otpDlgInput",
          inputLabel: "Code sent by Email",
          inputValue: "",
          inputRules: [rules().required],
          inputOnInput: (value) => {
            otpDlgInput.inputValue = value.replace(/\s+/g, "") //remove spaces
          },
        }
        const modalConfig = {
          id: "otpSentDlg",
          title: "Two-Factor Authentication Code",
          content: challengeParameters.prompt,
          dlgInputList: [otpDlgInput],
        }

        const modalResult = await this.$root.modal(modalConfig) //cannot use this.$root.emit because it does not support await
        if (!modalResult) return

        const otp = otpDlgInput.inputValue
        cognitoUser.sendCustomChallengeAnswer(otp, {
          onSuccess,
          onFailure,
        })
      }
      cognitoUser.setAuthenticationFlowType("CUSTOM_AUTH")
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess,
        onFailure,
        newPasswordRequired,
        customChallenge,
      })
    },
    async confirmNewPassword(newPassword) {
      this.cognitoUser.completeNewPasswordChallenge(
        newPassword,
        {},
        {
          onSuccess: (_user) => {
            this.$emit("passwordUpdated")
            this.$emit("snackbar", {
              id: "newPasswordRequiredSuccess",
              content: "Password updated. Please try to Login.",
            })
          },
          onFailure: (error) => {
            this.$emit("snackbar", {
              id: "newPasswordRequiredFailed",
              content: error.message || JSON.stringify(error),
            })
          },
        },
      )
    },
    async doLogout() {
      const { currentCognitoUser } = this.tryGetCurrentCognitoUser()
      if (currentCognitoUser) currentCognitoUser.signOut()
      this.emitCognitoUser(null, null)
      this.$emit("loggedOut")
      return Promise.resolve()
    },
    emitCognitoUser(cognitoUser) {
      this.$emit("cognito", cognitoUser)
    },
    /** Returns the Current CognitoUser and the appType */
    tryGetCurrentCognitoUser() {
      /** @type {String} */
      let appName = appTypeClient
      /** @type {CognitoUser} */
      let currentCognitoUser = null
      const userPoolClient = new CognitoUserPool(this.poolData(appTypeClient))
      currentCognitoUser = userPoolClient.getCurrentUser()
      if (!currentCognitoUser) {
        appName = appTypeBusiness
        const userPoolBusiness = new CognitoUserPool(this.poolData(appTypeBusiness))
        currentCognitoUser = userPoolBusiness.getCurrentUser()
      }
      return { currentCognitoUser, appName }
    },
  },
}
</script>
