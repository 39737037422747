import Vue from "vue"
import Vuetify from 'vuetify'
import "vuetify/dist/vuetify.min.css"
import colors from "vuetify/lib/util/colors"
import "@mdi/font/css/materialdesignicons.css"

Vue.use(Vuetify)

const opts = {
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.blue.darken1,
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        background: colors.grey.lighten5,
      },
      dark: {
        primary: colors.blue.lighten3,
        background: colors.indigo.base,
      },
      icons: {
        iconfont: "mdi",
      },
    },
  },
}

export default new Vuetify(opts)