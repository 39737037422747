<template>
  <div class="LoginApp" :class="{ logged }">
    <v-app :style="{ background: $vuetify.theme.themes[theme].background }" :class="[bclAppStyle]">
      <v-main class="loginAppMain">
        <CredentialForm
          class="credentialForm"
          :appName="$root.appName"
          :cognitoUser="$root.cognitoUser"
          :loadingOthers="logged"
          :api="api"
          @snackbar="$root.snackbar"
          @modal="$root.modal"
          @logged="onLogged"
          @cognito="onCognito"
          skipLocalLogin
        >
          <template v-slot:logoImage>
            <div class="logoWrapper">
              <v-card-text v-if="bPublic && bPublic.bLogoImage">
                <img class="loginLogo bLogoImage" alt="Company Logo" :src="bPublic.bLogoImage" />
                <div class="bLocationName text-h5" v-text="bPublic.bName" />
                <div v-if="bLocationAddress" v-text="bLocationAddress" class="bLocationAddress text--secondary" />
              </v-card-text>
              <img v-else class="loginLogo" alt="Company Logo" src="@/../../../img/goClientConnect-600px.png" />
            </div>
          </template>
        </CredentialForm>
        <div class="appStatusInner">
          <div class="appVersion" v-text="$root.appVersion" />
        </div>
      </v-main>
      <Dialog ref="dialog" />
    </v-app>
  </div>
</template>

<script>
import { BusinessProfile } from "./../../../../server/models/types"
import CredentialForm from "./../../../components/CredentialForm.vue"
import { sleep } from "./../../../../server/common/utils"
import api from "./service/api.js"
api.setup({ apiCognitoUserIgnore: true })
const urlParams = new URLSearchParams(window.location.search)
//urls like /login/index.html?bUrl=123. But it should be /login/index/abc-company
const bUrl = urlParams.get("b")

export default {
  name: "LoginApp",
  data() {
    return {
      bUrl,
      logged: false,
      appName: window.localStorage["appConnect"] || "",
      bPublic: null,
      bCustomLayout: null,
      api,
      apiA: false,
    }
  },
  watch: {
    bclPrimaryColor(newValue) {
      this.$vuetify.theme.themes.light.primary = newValue
    },
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light"
    },
    bclPrimaryColor() {
      return this?.bCustomLayout?.bclPrimaryColor || BusinessProfile.bclPrimaryColorDefault
    },
    bclAppStyle() {
      return this?.bCustomLayout?.bclAppStyle || BusinessProfile.bclAppStyleDefault
    },
    bLocationAddress() {
      if (!this.bPublic) return ""

      let address = ""
      const addToAddress = (toAdd, separator = ", ") => {
        if (address) address += separator
        address += toAdd
      }

      addToAddress(this.bPublic.bLocation.address)
      addToAddress(this.bPublic.bLocation.city)
      addToAddress(this.bPublic.bLocation.country, " - ")
      addToAddress(this.bPublic.bLocation.zipcode, " - ")

      return address
    },
  },
  components: {
    CredentialForm,
    Dialog: () => import("./../../../components/Dialog.vue"),
  },
  async mounted() {
    this.apiVersion()
    if (bUrl) {
      const result = await api.any().getBusinessProfileByUrl(bUrl)
      if (result?.data) {
        const { bPublic, bCustomLayout } = result.data
        this.$set(this, "bPublic", bPublic)
        this.$set(this, "bCustomLayout", bCustomLayout)
      }
    }

    this.initialLoaderWrapperRemover()
  },
  methods: {
    initialLoaderWrapperRemover() {
      const initialLoaderWrapperEl = document.querySelector(".initialLoaderWrapper")
      const transitionTime = 300
      if (initialLoaderWrapperEl) {
        initialLoaderWrapperEl.style.transition = `opacity ${transitionTime}ms`
        initialLoaderWrapperEl.style.opacity = 0
        setTimeout(() => {
          initialLoaderWrapperEl.remove() //remove it from the dom
        }, transitionTime + 1)
      }
    },
    onCognito(cognitoUser) {
      this.$set(this.$root, "cognitoUser", cognitoUser)
    },
    async onLogged({ cognitoUser, appName }) {
      window.localStorage["appConnect"] = appName
      this.$root.appName = appName
      this.$set(this.$root, "cognitoUser", cognitoUser)

      this.logged = true
      await sleep(1000)
      this.goToAdmin()
    },
    goToAdmin() {
      window.location.href = `//${window.location.host}/admin/index.html`
    },
    async apiVersion() {
      this.apiA = false
      const result = await api.any().apiVersion()
      this.apiA = result.ok
    },
  },
}
</script>


<style lang="scss" >
@import "./../../../components/AllComponents.scss";

.LoginApp {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f9f9f9;
}

.bName {
  font-size: 26px;
  opacity: 0.9;
  max-width: 90vw;
  width: 100%;
  margin: auto;
}

.credentialForm {
  margin-top: 160px;
  margin-bottom: 20px;
}

.loginLogo {
  width: auto;
  max-height: 115px;
}

.LoginApp .v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
  margin-left: 16px;
}

.v-application {
  .loginAppMain .CardPanel {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
    padding: 0px !important;
  }
}

.v-card {
  padding: 16px;
}

.borderless {
  border: none !important;
}

.appStatusInner {
  margin-left: 5px;
  display: inline-flex;
  line-height: 19px;
  .appVersion {
    opacity: 0.5;
    font-size: 13px;
    margin-right: 10px;
  }
  .apiVersionDiv {
    pointer-events: none;
  }
}
</style>
